@font-face {
  font-family: 'EixampleVillaRegular';
  font-weight: 400;
  src: local('EixampleVillaBold'), url(./fonts/EixampleVillaRegular.OTF) format('opentype');
}

@font-face {
  font-family: 'EixampleVillaBold';
  font-weight: 700;
  src: local('EixampleVillaBold'), url(./fonts/EixampleVillaBold.OTF) format('opentype');
}

@font-face {
  font-family: 'EixampleVillaExtraBold';
  font-weight: 800;
  src: local('EixampleVillaExtraBold'), url(./fonts/EixampleVillaExtraBold.OTF) format('opentype');
}

@font-face {
  font-family: 'EixampleVillaHeavy';
  font-weight: 900;
  src: local('EixampleVillaBold'), url(./fonts/EixampleVillaHeavy.OTF) format('opentype');
}

@font-face {
  font-family: 'TandelleRegular';
  font-weight: 400;
  src: local('TandelleBold'), url(./fonts/TandelleRegular.OTF) format('opentype');
}

@font-face {
  font-family: 'TandelleBold';
  font-weight: 700;
  src: local('TandelleBold'), url(./fonts/TandelleBold.OTF) format('opentype');
}


body {
  box-sizing: border-box;
  font-family: 'Righteous', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: antiquewhite;
  background-image: url('../public/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-modal-error {
  background-color: #E01B27;
}

.btn-submit {
  /* font-family: 'EixampleVillaBold'; */
  font-size: 18px;
  background-color: #4882E6;
  border-color: #4882E6;
  color: white;
}

.btn-submit:focus {
  color: white;
  border-color: #1D2438;
  background-color: #1D2438;
}

.btn-submit:hover {
  color: white;
  border-color: #1D2438;
  background-color: #1D2438;
}

.text-verified {
  /* font-family: 'EixampleVillaBold'; */
  font-size: 60px;
  color: #2CB14A;
}

.text-user {
  /* font-family: 'EixampleVillaBold'; */
  font-size: 65px;
  color: white;
}

.text-company {
  /* font-family: 'EixampleVillaBold'; */
  color: white;
  font-size: 40px;
}

.text-concurrent {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: white;
}

.text-breakout-room {
  /* font-family: 'TandelleBold'; */
  font-size: 70px;
  color: white;
}

.text-breakout-room-concurrent {
  /* font-family: 'Inter'; */
  font-size: 40px;
  color: #FE0000;
}